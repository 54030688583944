import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IEversoulCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import './employee.scss';
import './eversoul-character-dynamic.scss';
import { Alert, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { RatingBox } from '../modules/common/components/rating-box';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import lodash from 'lodash';
import { EversoulSpecialitiesArray } from '../modules/es/character/model/eversoul-specialities-array';
import {
  faBook,
  faCircleInfo,
  faFlask,
  faHandFist,
  faHeart,
  faShieldHalved,
  faSquare,
  faVirus,
  faWineGlass
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ESCharacterRatings } from '../modules/es/ratings/es-ratings';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IEversoulCharacterNodes {
  nodes: IEversoulCharacter[];
}

interface IEversoulCharacterEntry {
  currentUnit: IEversoulCharacterNodes;
}

interface IProps {
  data: IEversoulCharacterEntry;
}

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(EversoulSpecialitiesArray, {
    value: speciality
  });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

const EversoulCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-es-v2'}
      game="es"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/eversoul/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`${character.type}`}>{character.name}</strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background ${character.type} ${character.slug}`}
            ></div>
            <GatsbyImage image={fullImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div className={`content-header ${character.type}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`${character.type}`}>
                    {character.name}
                  </strong>{' '}
                  is a <strong className={`Epic`}>Epic</strong> rarity character
                  from the{' '}
                  <strong className={`${character.class}`}>
                    {character.class}
                  </strong>{' '}
                  class who belongs to the{' '}
                  <strong className={`${character.type}`}>
                    {character.type}
                  </strong>{' '}
                  faction and who uses the{' '}
                  <strong
                    className={`${
                      character.gearType ? character.gearType : ''
                    }`}
                  >
                    {character.gearType ? character.gearType : ''}
                  </strong>{' '}
                  gear type.
                </h2>
                <p>
                  {character.description ? (
                    <p>{character.description.description}</p>
                  ) : (
                    ''
                  )}
                </p>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`${character.type}`}>{character.name}</strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab ${character.type} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab ${character.type} ${
              currentTab === 'Artifact' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Artifact')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faWineGlass} width="18" />
            </div>
            <p>Artifact</p>
          </div>
          <div
            className={`single-tab ${character.type} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab ${character.type} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faHeart} width="18" />
            </div>
            <p>Lore & Love Story</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.type}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Specialties
          </div>
          <div className="specialities-list">
            {character.specialities ? (
              character.specialities.map((speciality, idx) => {
                return <SpecialityItem key={idx} speciality={speciality} />;
              })
            ) : (
              <span className="no-spec">-</span>
            )}
          </div>
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Build info
          </div>
          <Row xs={1} xxl={2} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category pve">PVE</div>
                <div className="details">
                  {character.setPve ? character.setPve : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category raid">Raids</div>
                <div className="details">
                  {character.setRaids ? character.setRaids : '-'}
                </div>
              </div>
            </Col>
          </Row>
          {character.setComments && (
            <div className="build-comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <div className={`review raw`}>
                {renderRichText(character.setComments, options)}
              </div>
            </div>
          )}
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.skills ? (
            <>
              <Row xs={1} xxl={2} className="skills">
                {character.skills.map((skill, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div className={`skill-icon ${character.type}`}>
                            {skill.type}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">{skill.name}</p>
                          </div>
                        </div>
                        <div className={`additional-information`}>
                          <p>
                            Type:{' '}
                            <span className={`cat-${skill.category}`}>
                              {skill.category ? <>{skill.category}</> : <>-</>}
                            </span>
                          </p>
                        </div>
                        <div className={`skill-description`}>
                          {renderRichText(skill.description, options)}
                        </div>
                        <div className="skill-upgrades">
                          {skill.upgrade1 && (
                            <div className="skill-level">
                              <span className="skill-upgrade">
                                {index === 0 && 'Level 101:'}
                                {index === 1 && 'Level 81:'}
                                {index === 2 && 'Level 21:'}
                                {index === 3 && 'Level 41:'}
                                {index === 4 && 'Level 61:'}
                              </span>{' '}
                              {renderRichText(skill.upgrade1, options)}
                            </div>
                          )}
                          {skill.upgrade2 && (
                            <div className="skill-level">
                              <span className="skill-upgrade">
                                {index === 0 && 'Level 201:'}
                                {index === 1 && 'Level 181:'}
                                {index === 2 && 'Level 121:'}
                                {index === 3 && 'Level 141:'}
                                {index === 4 && 'Level 161:'}
                              </span>{' '}
                              {renderRichText(skill.upgrade2, options)}
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                skills aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Partner Skills
          </div>
          {character.partnerSkills ? (
            <>
              <Row xs={1} xxl={2} className="skills">
                {character.partnerSkills.map((skill, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div className={`skill-icon ${character.type}`}>
                            {skill.type}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">
                              {skill.name ? skill.name : 'Missing name'}
                            </p>
                          </div>
                        </div>
                        <div className={`additional-information`}>
                          <p>
                            Type:{' '}
                            <span className={`cat-${skill.category}`}>
                              {skill.category ? <>{skill.category}</> : <>-</>}
                            </span>
                          </p>
                        </div>
                        <div className="skill-content">
                          <div className="skill-description">
                            <p>
                              <strong>Level 1:</strong>{' '}
                              {skill.level_1 ? skill.level_1 : '-'}
                            </p>
                            <p>
                              <strong>Level 7:</strong>{' '}
                              {skill.level_7 ? skill.level_7 : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                skills aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          {fullImage ? (
            <GatsbyImage image={fullImage} alt="" className="full-image" />
          ) : (
            <Alert variant="primary">
              <Alert.Heading>
                No full image is available for this character yet
              </Alert.Heading>
              <p>We will add it as soon as it is possible!</p>
            </Alert>
          )}
        </div>
        <div
          className={`tab-inside ${currentTab === 'Artifact' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.type}`}>
            <p>
              <FontAwesomeIcon icon={faWineGlass} width="18" />
              Artifact
            </p>
          </div>
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Artifact Skill
          </div>
          {character.artifactItem ? (
            <>
              <Row xs={1} xxl={1} className="skills">
                <Col>
                  <div className="box">
                    <div className="skill-header">
                      <div className={`skill-icon ${character.type}`}>
                        Artifact
                      </div>
                      <div className="skill-info">
                        <p className="skill-name">
                          {character.artifactItem.name}
                        </p>
                      </div>
                    </div>
                    <div className={`additional-information`}>
                      <p>
                        Type:{' '}
                        <span
                          className={`cat-${character.artifactItem.category}`}
                        >
                          {character.artifactItem.category ? (
                            <>{character.artifactItem.category}</>
                          ) : (
                            <>-</>
                          )}
                        </span>
                      </p>
                    </div>
                    <div className={`skill-description`}>
                      {renderRichText(
                        character.artifactItem.description,
                        options
                      )}
                    </div>
                    <div className="skill-upgrades">
                      <div className="skill-level">
                        <span className="skill-upgrade">Upgrade 1:</span>{' '}
                        {renderRichText(character.artifactItem.level1, options)}
                      </div>
                      <div className="skill-level">
                        <span className="skill-upgrade">Upgrade 2:</span>{' '}
                        {renderRichText(character.artifactItem.level2, options)}
                      </div>
                      <div className="skill-level">
                        <span className="skill-upgrade">Upgrade 3:</span>{' '}
                        {renderRichText(character.artifactItem.level3, options)}
                      </div>
                      <div className="skill-level">
                        <span className="skill-upgrade">Upgrade 4:</span>{' '}
                        {renderRichText(character.artifactItem.level4, options)}
                      </div>
                      <div className="skill-level">
                        <span className="skill-upgrade">Upgrade 5:</span>{' '}
                        {renderRichText(character.artifactItem.level5, options)}
                      </div>
                      <div className="skill-level">
                        <span className="skill-upgrade">Upgrade 6:</span>{' '}
                        {renderRichText(character.artifactItem.level6, options)}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className={`content-header ${character.type}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Artifact Stats
              </div>
              <Row xs={1} xxl={4} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">
                      {character.artifactItem.stats.stat1_name}
                    </div>
                    <div className="details">
                      {character.artifactItem.stats.stat1_value}%
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">
                      {character.artifactItem.stats.stat2_name}
                    </div>
                    <div className="details">
                      {character.artifactItem.stats.stat2_value}%
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">
                      {character.artifactItem.stats.stat3_name}
                    </div>
                    <div className="details">
                      {character.artifactItem.stats.stat3_value}%
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">
                      {character.artifactItem.stats.stat4_name}
                    </div>
                    <div className="details">
                      {character.artifactItem.stats.stat4_value}%
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                artifact information aren't available yet. They will be added
                soon!
              </p>
            </div>
          )}
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.type}`}>
            <p>
              <FontAwesomeIcon icon={faBook} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {!character.isReviewPending ? (
            <>
              {ESCharacterRatings.map((char, index) => {
                return (
                  <div key={index}>
                    {char.Slug === character.slug && (
                      <>
                        <h5>
                          {char.Role === 'DPS' && (
                            <span className="role dps">
                              <FontAwesomeIcon icon={faHandFist} width="12" />{' '}
                              Damage dealer
                            </span>
                          )}
                          {char.Role === 'Debuffer' && (
                            <span className="role debuffer">
                              <FontAwesomeIcon icon={faVirus} width="12" />{' '}
                              Debuffer
                            </span>
                          )}
                          {char.Role === 'Buffer' && (
                            <span className="role buffer">
                              <FontAwesomeIcon icon={faFlask} width="12" />{' '}
                              Buffer / Sustain
                            </span>
                          )}
                          {char.Role === 'Tank' && (
                            <span className="role tank">
                              <FontAwesomeIcon
                                icon={faShieldHalved}
                                width="18"
                              />{' '}
                              Tank
                            </span>
                          )}{' '}
                        </h5>
                        <div className="detailed-ratings es">
                          <RatingBox
                            game="new-nikke"
                            rating={char.PVE}
                            ratingName="PVE (Endgame)"
                          />
                          <RatingBox
                            game="new-nikke"
                            rating={char.Boss_Overall}
                            ratingName="Boss (Overall)"
                          />
                          <RatingBox
                            game="new-nikke"
                            rating={char.Boss_Charite}
                            ratingName="Boss (Charite)"
                          />
                          <RatingBox
                            game="new-nikke"
                            rating={char.Boss_Gaia}
                            ratingName="Boss (Gaia)"
                          />
                          <RatingBox
                            game="new-nikke"
                            rating={char.Boss_Rudra}
                            ratingName="Boss (Rudra)"
                          />
                          <RatingBox
                            game="new-nikke"
                            rating={char.Boss_Knight}
                            ratingName="Boss (Knight)"
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                <strong>ratings aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          <div className="info-box with-margin">
            <p>
              We have reworked our tier list owing to multiple meta shifts and
              how we rate characters. As such some of the reviews (especially of
              older characters) might not match the tier list ratings. Please
              prioritize the ratings given over provided Review. We will be
              working on updating all reviews, but it is a time-consuming
              endeavor.
            </p>
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.type}`}>
            <p>
              <FontAwesomeIcon icon={faHeart} width="18" />
              Lore & Love Story
            </p>
          </div>
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Love Story
          </div>
          {character.trueEnding ? (
            <>
              <div className="info-box with-margin">
                <p>
                  The answers below are the correct ones you have to pick to
                  achieve the True Ending{' '}
                  <strong>(in the order you have to do so)</strong>.
                </p>
              </div>
              <div className="true-ending">
                {renderRichText(character.trueEnding, options)}
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.type}`}>
                  {character.name}
                </strong>{' '}
                <strong>love story solution isn't available yet.</strong> It
                will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> VA & Release
          </div>
          <Row xs={1} xxl={3} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">Release date</div>
                <div className="details">{character.releaseDate}</div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">VA (KR)</div>
                <div className="details">
                  {character.voiceActors.kr ? character.voiceActors.kr : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">VA (JPN)</div>
                <div className="details">
                  {character.voiceActors.jpn ? character.voiceActors.jpn : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header ${character.type}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Various information
          </div>
          <Row xs={1} xl={2} xxl={3} className="info-list ">
            <Col>
              <h5>Personal information</h5>
              <div className="info-list-row">
                <div className="category">Name</div>
                <div className="details">{character.name}</div>
              </div>
              <div className="info-list-row">
                <div className="category">Alias</div>
                <div className="details">
                  {character.alias ? character.alias : '-'}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Height</div>
                <div className="details">
                  {character.information.height
                    ? character.information.height
                    : '-'}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Birthday</div>
                <div className="details">
                  {character.information.birthday
                    ? character.information.birthday
                    : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <h5>Combat information</h5>
              <div className="info-list-row">
                <div className="category">Rarity</div>
                <div className="details">
                  <span className={`color-rarity eversoul ${character.rarity}`}>
                    {character.rarity}
                  </span>
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Type</div>
                <div className="details">
                  <strong className={`${character.type}`}>
                    {character.type}
                  </strong>{' '}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Class</div>
                <div className="details">
                  <strong className={`${character.class}`}>
                    {character.class}
                  </strong>{' '}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Gear type</div>
                <div className="details">
                  {character.gearType ? character.gearType : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <h5>Hobby and other information</h5>
              <div className="info-list-row">
                <div className="category">Likes</div>
                <div className="details">
                  {character.information.likes
                    ? character.information.likes
                    : '-'}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Dislikes</div>
                <div className="details">
                  {character.information.dislikes
                    ? character.information.dislikes
                    : '-'}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">Hobby</div>
                <div className="details">
                  {character.information.hobby
                    ? character.information.hobby
                    : '-'}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default EversoulCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Eversoul | Prydwen Institute'}
      description={
        character.description
          ? character.description.description
          : 'One of the characters in Eversoul.'
      }
      image={character.smallImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulEversoulCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...EversoulCharacterFieldsFragment
      }
    }
  }
`;
